//
// Aside Brand
//



.kt-aside__brand {
	display: flex;
	flex-direction: row;
	padding: 0 kt-get($kt-page-padding, desktop);
	height: kt-get($kt-header-config, base, desktop, default, height);
	background-color: #1a1a27;
	justify-content: center;
	align-self: center;
	width: 100%;
	
	.kt-aside__brand-logo {
		display: flex;
		align-self: center;
		justify-content: center;

		img {
			.kt-aside--minimize & {
				display: none;
			}
		}
	}
}

@include kt-desktop {
	.kt-aside__brand {
		transition: kt-get($kt-aside-config, base, minimize, transition);

		.kt-header--fixed.kt-aside--static & {
			position: fixed;
			top: 0;
			left: 0;
			width: kt-get($kt-aside-config, base, default, width);
			z-index: kt-get($kt-aside-config, base, fixed, zindex);
		}

		.kt-header--fixed.kt-aside--static.kt-aside--minimize & {
			transition: kt-get($kt-aside-config, base, minimize, transition);
			width: kt-get($kt-aside-config, base, minimize, width);
		}
	}
}

// Tablet and mobile mode
@include kt-tablet-and-mobile {
	.kt-aside__brand {
		display: none;
	}
}